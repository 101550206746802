import { useEffect, useState } from 'react'

export type State = NotificationPermission

export const useNotificationsState = (): State => {
  const [state, setState] = useState<State>(getNotificationsState())

  useEffect(() => {
    const handlePermissionChange = (): void => {
      setState(getNotificationsState())
    }
    // old browsers do not support permissions
    void navigator.permissions?.query({ name: 'notifications' }).then((notificationsStatus) => {
      notificationsStatus.addEventListener('change', handlePermissionChange)
    })
    return () => {
      void navigator.permissions?.query({ name: 'notifications' }).then((notificationsStatus) => {
        notificationsStatus.removeEventListener('change', handlePermissionChange)
      })
    }
  }, [])

  return state
}

export function getNotificationsState(): State {
  if ('Notification' in window && 'permission' in Notification) {
    return Notification.permission
  }

  return 'denied'
}

export async function requestNotificationsPermission(): Promise<NotificationPermission> {
  if ('Notification' in window && 'requestPermission' in Notification) {
    return await Notification.requestPermission()
  }

  return 'denied'
}
